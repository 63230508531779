<template>
  <section>
      <div class="content-header">
        <h2>Magazine articles</h2>
      </div>
      <div class="content-wrapper">
        <ul>
          <li class="pb-3"><a href="https://www.forbes.com/sites/marvinkrislov/2019/08/27/how-we-build-resilience-at-college-and-at-home/?sh=781ef8fd44b5" target="_blank">How We Build Resilience at College and at Home</a></li>
          <li class="pb-3"><a href="https://www.nytimes.com/2019/01/23/learning/empathy-and-resilience-responsibility-and-self-care-resources-for-social-and-emotional-learning-from-the-new-york-times.html" target="_blank">Empathy and Resilience, Responsibility and Self-Care: Resources for Social and Emotional Learning from The New York Times</a></li>
          <li class="pb-3"><a href="https://www.buzzfeed.com/tanyachen/thank-you-professor-langer" target="_blank">A Student Cried After Receiving This Touching "Thank You" Email from Her Professor</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
